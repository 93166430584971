<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2021-06-04 16:22:17
 * @LastEditTime: 2021-06-29 16:52:27
-->
<template>
	<div class="layout">
		<el-container>
			<el-header v-if="showHeader">
				<div class="header-box">
					<div @click="backToPrev" class="header-box-back">
						<img src="@/assets/images/pc/common/back-header.png" alt="">
						<span>返回</span>
					</div>
					<div class="header-box-userinfo">
						<el-dropdown trigger="click">
							<span class="header-box-name">
								{{userInfo.account}}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item>
									<div @click="loginOut">退出登录</div>
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</el-header>
			<!-- 主体部分 -->
			<el-main :class="{'el-main-hasheader': showHeader}">
				<router-view></router-view>
			</el-main>
		</el-container>
	</div>
</template>
<script>
	import storage from 'store'
	export default {
		name: "pcRouteView",
		data() {
			return {
				showHeader: false,
				userInfo: {},
			};
		},
		watch: {
			$route: {
				handler(to) {
					// console.log("$router", to)
					storage.set("localRouterPc", to.fullPath)
					this.showHeader = to.meta.showHeader
				},
				immediate: true,
			}
		},
		mounted() {
			this.userInfo = storage.get("accessInfo")
		},
		methods: {
			loginOut() {
				this.$store.dispatch("pcLogout").then(() => {
					this.$router.push({
						path: `/pc/user/login`
					})
					setTimeout(() => {
						window.location.reload();
					}, 16);
				});
				// storage.remove("identityPc")
				// storage.remove("accessToken")
				// this.$router.push({
				// 	path: `/pc/user/identity`
				// })
			},
			backToPrev() {
				console.log(this.$route)
				// this.$router.go(-1)
				this.$router.push({
					path: this.$route.meta.parentPath
				})
			}
		},
	};
</script>
<style lang="scss" scoped>
	.layout {
		.el-container {
			@extend .layout;
			display: flex;
			flex-direction: column;
			
			.header-box {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 50px;
				padding: 0 28px;
				box-sizing: border-box;
				border-bottom: 1px solid #DCDFE6;
				background:#4392FA;
				.header-box-back {
					display: flex;
					align-items: center;
					cursor: pointer;
					img {
						width: 16px;
						height: 16px;
						margin-right: 6px;
					}
					span {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 20px;
						color:#ffffff;
					}
				}
				
				.header-box-userinfo {
					height: 50px;
					display: flex;
					align-items: center;
				
					.header-box-name {
						font-size: 14px;
						font-family: PingFang SC;
						font-weight: 400;
						line-height: 20px;
						color: #ffffff;
						cursor: pointer;
					}
				}
			}
			
			.el-header {
				padding: 0 0;
				margin: 0 0;
				height: 50px !important;
				// z-index: 2022 !important;
			}
			
			.el-main-hasheader {
				height: calc(100vh - 50px) !important;
			}

			.el-main {
				margin: 0 0;
				padding: 0 28px;
				height: 100vh;
			}
		}
	}
</style>
